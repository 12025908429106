import React, { useState, useEffect, useContext, useMemo } from 'react';
import {
  DialogActions,
  Dialog,
  DialogContent,
  Button,
  Autocomplete,
  DialogTitle,
  TextField,
  Stack,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Box,
  IconButton
} from '@mui/material';
import PropTypes from 'prop-types';
import { gql } from '@apollo/client';
import { useTranslation } from 'react-i18next';

import { useAnalyticsFilters } from '../../../../hooks/analyticsFilters';
import Loading from '../../../../atoms/Loading';
import { useCustomQuery } from '../../../../hooks/graphql';
import UserContext from '../../../../context';
import { Add, Remove } from '@mui/icons-material';

const FILTER_PRODUCT = gql`
  query AttribTargetsModal(
    $from: String!
    $to: String!
    $timezone: String!
    $conversionMethodology: String!
    $clientId: ID!
    $ecommerceObjectIndex: Int
    $viewId: ID
  ) {
    datePickerFrom @client @export(as: "from")
    datePickerTo @client @export(as: "to")
    client(clientId: $clientId) {
      id
      attributionPings(viewId: $viewId, from: $from, to: $to) {
        products(
          conversionMethodology: $conversionMethodology
          from: $from
          to: $to
          timezone: $timezone
          ecommerceObjectIndex: $ecommerceObjectIndex
        ) {
          list {
            id
            name
          }
        }
      }
    }
  }
`;

const ProductIdModal = ({
  handleClose,
  isOpen,
  viewFilters,
  selectedFilters,
  handleOnUpdate,
  activeProductObjectIndex
}) => {
  const { t } = useTranslation();
  const [values, setValues] = useState([]);
  const [containsType, setContainsType] = useState('or');
  const [containsValues, setContainsValues] = useState(['']);
  const [productFilterType, setProductFilterType] = useState('');
  const { filters } = useAnalyticsFilters();
  const { clientSettings } = useContext(UserContext);
  const activeProductObjectToUse =
    activeProductObjectIndex === 'null' ? null : activeProductObjectIndex;

  const { data } = useCustomQuery(FILTER_PRODUCT, {
    variables: {
      clientId: clientSettings.id,
      conversionMethodology:
        clientSettings.attributionPingsSettings.conversionMethodology,
      timezone: clientSettings.timezone,
      ecommerceObjectIndex: activeProductObjectToUse
    },
    showLoader: false,
    skip:
      !isOpen ||
      activeProductObjectToUse === undefined ||
      productFilterType !== 'product',
    fetchPolicy: 'no-cache'
  });

  useEffect(() => {
    if (isOpen) {
      const mergedFilters = selectedFilters;

      setValues(
        selectedFilters?.find(
          ({ type }) => type === `product_id-${activeProductObjectToUse}`
        )?.ids || []
      );
      if (
        mergedFilters?.some(
          ({ type }) =>
            type === `product_name_ContainsOr-${activeProductObjectToUse}`
        )
      ) {
        setContainsValues(
          mergedFilters?.find(
            ({ type }) =>
              type === `product_name_ContainsOr-${activeProductObjectToUse}`
          )?.ids || []
        );
        setContainsType('or');
        setProductFilterType('contains');
      }

      if (
        mergedFilters?.some(
          ({ type }) =>
            type === `product_name_ContainsAnd-${activeProductObjectToUse}`
        )
      ) {
        setContainsValues(
          mergedFilters?.find(
            ({ type }) =>
              type === `product_name_ContainsAnd-${activeProductObjectToUse}`
          )?.ids || []
        );
        setContainsType('and');
        setProductFilterType('contains');
      }

      if (
        mergedFilters?.some(
          ({ type }) =>
            type === `product_name_NotContainsOr-${activeProductObjectToUse}`
        )
      ) {
        setContainsValues(
          mergedFilters?.find(
            ({ type }) =>
              type === `product_name_NotContainsOr-${activeProductObjectToUse}`
          )?.ids || []
        );
        setContainsType('or');
        setProductFilterType('notContains');
      }

      if (
        mergedFilters?.some(
          ({ type }) =>
            type === `product_name_NotContainsAnd-${activeProductObjectToUse}`
        )
      ) {
        setContainsValues(
          mergedFilters?.find(
            ({ type }) =>
              type === `product_name_NotContainsAnd-${activeProductObjectToUse}`
          )?.ids || []
        );
        setContainsType('and');
        setProductFilterType('notContains');
      }
    }
  }, [
    selectedFilters,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    JSON.stringify(filters),
    isOpen,
    activeProductObjectToUse
  ]);

  const optionsList = useMemo(
    () =>
      data?.client?.attributionPings?.products?.list
        ?.filter(
          ({ id }) =>
            !viewFilters?.some(
              ({ type }) => type === `product_id-${activeProductObjectToUse}`
            ) ||
            viewFilters?.some(
              ({ type, ids }) =>
                type === `product_id-${activeProductObjectToUse}` &&
                ids.includes(id)
            )
        )
        .map(({ id }) => id),
    [data, viewFilters, activeProductObjectToUse]
  );

  return (
    <Dialog maxWidth="sm" fullWidth onClose={handleClose} open={isOpen}>
      <DialogTitle>
        {t('ma.filter.filterByProductName', {
          name: clientSettings?.attributionPingsSettings?.customAttributes?.find(
            ca =>
              ca.type === 'ecommerceObject' &&
              ca.ecommerceObjectIndex === activeProductObjectToUse
          )?.name
        })}
      </DialogTitle>

      <DialogContent>
        {productFilterType === 'product' && !data ? (
          <Loading />
        ) : (
          <Stack spacing={2} mt={2}>
            <FormControl fullWidth>
              <InputLabel id="ty-select-product-filter-type">
                {t('ma.filter.targets.filterType')}
              </InputLabel>
              <Select
                label={t('ma.filter.targets.filterType')}
                labelId="ty-select-product-filter-type"
                id="ty-select-product-filter-type"
                value={productFilterType}
                onChange={e => {
                  setValues([]);
                  setContainsValues(['']);
                  setProductFilterType(e.target.value);
                }}
              >
                <MenuItem value="product">
                  {t('ma.filter.targets.exact')}
                </MenuItem>
                <MenuItem value="contains">
                  {t('ma.filter.targets.contains')}
                </MenuItem>
                <MenuItem value="notContains">
                  {t('ma.filter.targets.notContains')}
                </MenuItem>
              </Select>
            </FormControl>
            {productFilterType === 'product' && (
              <Autocomplete
                multiple
                value={values}
                onChange={(_, v) => setValues(v)}
                id="tags-standard"
                options={optionsList}
                getOptionLabel={option => {
                  const productData = data?.client?.attributionPings?.products?.list.find(
                    ({ id }) => id === option
                  );
                  return `${option} - ${productData?.name}`;
                }}
                renderInput={params => (
                  <TextField
                    {...params}
                    variant="standard"
                    label=""
                    placeholder={t('ma.filter.selectProductName')}
                  />
                )}
              />
            )}
            {productFilterType === 'contains' && (
              <>
                {containsValues.map((item, index) => {
                  return (
                    <Stack
                      key={index}
                      direction="row"
                      spacing={2}
                      alignItems="center"
                    >
                      {index > 0 && (
                        <Select
                          variant="standard"
                          value={containsType}
                          onChange={e => {
                            setContainsType(e.target.value);
                          }}
                        >
                          <MenuItem value="and">{t('commun.and')}</MenuItem>
                          <MenuItem value="or">{t('commun.or')}</MenuItem>
                        </Select>
                      )}
                      <TextField
                        fullWidth
                        onChange={e => {
                          setContainsValues(
                            containsValues.map((i, ind) =>
                              ind === index ? e.target.value : i
                            )
                          );
                        }}
                        placeholder={t('ma.filter.targets.term')}
                        value={item}
                        variant="standard"
                      />
                      {index === containsValues.length - 1 &&
                        containsValues.length < 5 && (
                          <Box>
                            <IconButton
                              size="small"
                              onClick={() =>
                                setContainsValues([...containsValues, ''])
                              }
                            >
                              <Add />
                            </IconButton>
                          </Box>
                        )}

                      <Box>
                        <IconButton
                          disabled={containsValues.length === 1}
                          size="small"
                          onClick={() =>
                            setContainsValues(
                              containsValues.filter((_, i) => i !== index)
                            )
                          }
                        >
                          <Remove />
                        </IconButton>
                      </Box>
                    </Stack>
                  );
                })}
              </>
            )}
            {productFilterType === 'notContains' && (
              <>
                {containsValues.map((item, index) => {
                  return (
                    <Stack
                      key={index}
                      direction="row"
                      spacing={2}
                      alignItems="center"
                    >
                      {index > 0 && (
                        <Select
                          variant="standard"
                          value={containsType}
                          onChange={e => {
                            setContainsType(e.target.value);
                          }}
                        >
                          <MenuItem value="and">{t('commun.and')}</MenuItem>
                          <MenuItem value="or">{t('commun.or')}</MenuItem>
                        </Select>
                      )}
                      <TextField
                        fullWidth
                        onChange={e => {
                          setContainsValues(
                            containsValues.map((i, ind) =>
                              ind === index ? e.target.value : i
                            )
                          );
                        }}
                        placeholder={t('ma.filter.targets.term')}
                        value={item}
                        variant="standard"
                      />
                      {index === containsValues.length - 1 &&
                        containsValues.length < 5 && (
                          <Box>
                            <IconButton
                              size="small"
                              onClick={() =>
                                setContainsValues([...containsValues, ''])
                              }
                            >
                              <Add />
                            </IconButton>
                          </Box>
                        )}

                      <Box>
                        <IconButton
                          disabled={containsValues.length === 1}
                          size="small"
                          onClick={() =>
                            setContainsValues(
                              containsValues.filter((_, i) => i !== index)
                            )
                          }
                        >
                          <Remove />
                        </IconButton>
                      </Box>
                    </Stack>
                  );
                })}
              </>
            )}
          </Stack>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="secondary">
          {t('commun.cancel')}
        </Button>
        <Button
          disabled={values?.length <= 0 && containsValues?.length <= 0}
          onClick={() => {
            handleOnUpdate(
              values,
              containsValues,
              productFilterType,
              containsType
            );
            handleClose();
          }}
          color="primary"
        >
          {t('commun.confirm')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

ProductIdModal.propTypes = {
  handleClose: PropTypes.func.isRequired,
  activeProductObjectIndex: PropTypes.number,
  isOpen: PropTypes.bool.isRequired,
  handleOnUpdate: PropTypes.func.isRequired,
  viewFilters: PropTypes.array.isRequired,
  selectedFilters: PropTypes.array.isRequired
};

export default ProductIdModal;
