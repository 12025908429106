import React, { useMemo, useContext } from 'react';
import { Chip, Grid } from '@mui/material';

import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { useAnalyticsHelpers } from '../../../../hooks/analyticsHelpers';
import UserContext from '../../../../context';
import { CONNECTORS_LIST } from '../../../../constants/connectors';

const FiltersRow = ({ handleOnClick, handleOnDelete, filters }) => {
  const { t } = useTranslation();
  const { findChannelName } = useAnalyticsHelpers();
  const { clientSettings } = useContext(UserContext);

  // Format data for front
  const listToDisplay = useMemo(() => {
    if (!filters) {
      return null;
    }

    return filters
      ?.map(filter => {
        if (filter.type === 'conversionStatus') {
          return {
            filterId: filter.type,
            filterName: 'Conversion status',
            values: filter.ids.map(id => ({
              id,
              name: t(`ma.backOfficeImport.status.${id || 'others'}`)
            }))
          };
        }

        if (filter.type === 'channel') {
          return {
            filterId: filter.type,
            filterName: t('commun.channel'),
            values: filter.ids.map(id => ({ id, name: findChannelName(id) }))
          };
        }

        if (filter.type === 'subChannel') {
          return {
            filterId: filter.type,
            filterName: t('ma.commun.subChannel'),
            values: filter.ids.map(id => ({ id, name: findChannelName(id) }))
          };
        }

        if (filter.type === 'target') {
          return {
            filterId: filter.type,
            filterName: t('commun.landingPage'),
            values: filter.ids.map(id => ({ id, name: id }))
          };
        }

        if (filter.type === 'targetContainsOr') {
          return {
            filterId: filter.type,
            filterName: t('ma.filter.targets.landingPageContains'),
            values: filter.ids.map(id => ({ id, name: id }))
          };
        }

        if (filter.type === 'targetContainsAnd') {
          return {
            filterId: filter.type,
            filterName: t('ma.filter.targets.landingPageContains'),
            values: filter.ids.map(id => ({ id, name: id }))
          };
        }

        if (filter.type === 'targetNotContainsOr') {
          return {
            filterId: filter.type,
            filterName: t('ma.filter.targets.landingPageNotContains'),
            values: filter.ids.map(id => ({ id, name: id }))
          };
        }

        if (filter.type === 'targetNotContainsAnd') {
          return {
            filterId: filter.type,
            filterName: t('ma.filter.targets.landingPageNotContains'),
            values: filter.ids.map(id => ({ id, name: id }))
          };
        }

        if (filter.type === 'conversion_type') {
          return {
            filterId: filter.type,
            filterName: t('ma.omnicanal.conversionTypes'),
            values: filter.ids.map(id => ({
              id,
              name: t(`ma.omnicanal.${id}`)
            }))
          };
        }

        if (filter.type === 'cutting') {
          return {
            filterId: filter.type,
            filterName: 'Détails',
            values: filter.ids.map(id => ({ id, name: id }))
          };
        }

        if (filter.type === 'cuttingContainsOr') {
          return {
            filterId: filter.type,
            filterName: t('ma.filter.details.detailsContains'),
            values: filter.ids.map(id => ({ id, name: id }))
          };
        }

        if (filter.type === 'cuttingContainsAnd') {
          return {
            filterId: filter.type,
            filterName: t('ma.filter.details.detailsContains'),
            values: filter.ids.map(id => ({ id, name: id }))
          };
        }

        if (filter.type === 'device_type') {
          return {
            filterId: filter.type,
            filterName: t('ma.commun.device'),
            values: filter.ids.map(id => ({
              id,
              name: t(`constants.devices.${id}`)
            }))
          };
        }

        if (filter.type === 'device_os') {
          return {
            filterId: filter.type,
            filterName: t('ma.commun.operatingSystem'),
            values: filter.ids.map(id => ({ id, name: id }))
          };
        }

        if (filter.type === 'viewport') {
          return {
            filterId: filter.type,
            filterName: t('ma.commun.windowSize'),
            values: filter.ids.map(id => ({ id, name: id }))
          };
        }

        if (filter.type === 'device_browser') {
          return {
            filterId: filter.type,
            filterName: t('ma.commun.browser'),
            values: filter.ids.map(id => ({ id, name: id }))
          };
        }

        if (filter.type === 'gscQuery') {
          return {
            filterId: filter.type,
            filterName: 'Google Search Console',
            values: filter.ids.map(id => ({ id, name: id }))
          };
        }

        if (filter.type === 'gscQueryContainsOr') {
          return {
            filterId: filter.type,
            filterName: t('ma.filter.gscQuery.queriesContains'),
            values: filter.ids.map(id => ({ id, name: id }))
          };
        }

        if (filter.type === 'gscQueryContainsAnd') {
          return {
            filterId: filter.type,
            filterName: t('ma.filter.gscQuery.queriesContains'),
            values: filter.ids.map(id => ({ id, name: id }))
          };
        }

        if (filter.type.startsWith('product_brand')) {
          return {
            filterId: filter.type,
            filterName: t('ma.filter.labelFilterProductBrand', {
              name: clientSettings?.attributionPingsSettings?.customAttributes?.find(
                ca =>
                  ca.type === 'ecommerceObject' &&
                  compareEcommerceIndex(
                    ca.ecommerceObjectIndex,
                    filter.type.split('-')[1]
                  )
              )?.name
            }),
            values: filter.ids.map(id => ({ id, name: id }))
          };
        }

        if (filter.type.startsWith('product_name_Contains')) {
          return {
            filterId: filter.type,
            filterName: t('ma.filter.labelFilterProductNameContains', {
              name: clientSettings?.attributionPingsSettings?.customAttributes?.find(
                ca =>
                  ca.type === 'ecommerceObject' &&
                  compareEcommerceIndex(
                    ca.ecommerceObjectIndex,
                    filter.type.split('-')[1]
                  )
              )?.name
            }),
            values: filter.ids.map(id => ({ id, name: id }))
          };
        }
        if (filter.type.startsWith('product_name_NotContains')) {
          return {
            filterId: filter.type,
            filterName: t('ma.filter.labelFilterProductNameNotContains', {
              name: clientSettings?.attributionPingsSettings?.customAttributes?.find(
                ca =>
                  ca.type === 'ecommerceObject' &&
                  compareEcommerceIndex(
                    ca.ecommerceObjectIndex,
                    filter.type.split('-')[1]
                  )
              )?.name
            }),
            values: filter.ids.map(id => ({ id, name: id }))
          };
        }

        if (filter.type.startsWith('product_cat')) {
          return {
            filterId: filter.type,
            filterName: t('ma.filter.labelFilterProductCat', {
              name: clientSettings?.attributionPingsSettings?.customAttributes?.find(
                ca =>
                  ca.type === 'ecommerceObject' &&
                  compareEcommerceIndex(
                    ca.ecommerceObjectIndex,
                    filter.type.split('-')[1]
                  )
              )?.name
            }),
            values: filter.ids.map(id => ({ id, name: id }))
          };
        }

        if (filter.type.startsWith('product_id')) {
          return {
            filterId: filter.type,
            filterName: t('ma.filter.labelFilterProductName', {
              name: clientSettings?.attributionPingsSettings?.customAttributes?.find(
                ca =>
                  ca.type === 'ecommerceObject' &&
                  compareEcommerceIndex(
                    ca.ecommerceObjectIndex,
                    filter.type.split('-')[1]
                  )
              )?.name
            }),
            values: filter.ids.map(id => ({ id, name: id }))
          };
        }

        if (filter.type === 'manualCostCampaignId') {
          return {
            filterId: filter.type,
            filterName: t('support.glossary.items.manualCost.title'),
            values: filter.ids.map(id => ({
              id,
              name:
                clientSettings?.attributionPingsSettings?.manualCosts?.find(
                  cost => id === cost.id
                )?.name || id
            }))
          };
        }

        if (filter.type === 'adsCostProviderId') {
          return {
            filterId: filter.type,
            filterName: t('commun.connector'),
            values: filter.ids.map(id => ({
              id,
              name:
                CONNECTORS_LIST?.find(connector => connector.id === id)?.name ||
                id
            }))
          };
        }

        if (
          filter.type.startsWith('customAttribute') ||
          filter.type.startsWith('audienceDimension')
        ) {
          // eslint-disable-next-line no-unused-vars
          const [, _, ...customAttributeIds] = filter.type.split('_');
          const customAttributeData = clientSettings.attributionPingsSettings.customAttributes.find(
            attr => attr.internalAttributKey === customAttributeIds.join('_')
          );

          if (customAttributeData) {
            if (customAttributeData.type === 'number') {
              return {
                filterId: filter.type,
                filterName: t(
                  filter.type.startsWith('customAttribute')
                    ? 'ma.commun.customAttribute'
                    : 'ma.commun.audienceDimension',
                  {
                    name: customAttributeData.name
                  }
                ),
                numericRelation: filter.ids[0].split('_')[1],
                values: [
                  {
                    id: filter.ids[0].split('_')[0],
                    name: filter.ids[0].split('_')[0]
                  }
                ]
              };
            }

            return {
              filterId: filter.type,
              filterName: t(
                filter.type.startsWith('customAttribute')
                  ? 'ma.commun.customAttribute'
                  : 'ma.commun.audienceDimension',
                {
                  name: customAttributeData.name
                }
              ),
              values: filter.ids.map(id => ({ id, name: id }))
            };
          }
        }

        return null;
      })
      .filter(p => p);
  }, [clientSettings.attributionPingsSettings, filters, findChannelName, t]);

  return listToDisplay.map((filter, indexFilter) =>
    filter.values.map(value => (
      <Grid item key={`${filter.filterId}_${value.id}`}>
        <Chip
          sx={{ marginBottom: '5px', marginLeft: '10px' }}
          label={
            <>
              {filter.filterName}
              {filter.numericRelation === 'greaterThan' && ' >='}
              {filter.numericRelation === 'lowerThan' && ' <='}
              {filter.numericRelation === 'equal' && ' ='}
              {` "${value.name}"`}
            </>
          }
          onDelete={
            handleOnDelete &&
            (() =>
              handleOnDelete(
                filter.filterId,
                filter.values
                  .filter(({ id }) => id !== value.id)
                  .map(({ id }) => id),
                indexFilter
              ))
          }
          variant="outlined"
          clickable={!!handleOnClick}
          onClick={
            handleOnClick &&
            (() => {
              handleOnClick(filter.filterId);
            })
          }
        />
      </Grid>
    ))
  );
};

FiltersRow.propTypes = {
  handleOnClick: PropTypes.func,
  handleOnDelete: PropTypes.func,
  filters: PropTypes.array.isRequired
};

const compareEcommerceIndex = (ecommerceObjectIndex, filterTypeIndex) => {
  return (
    (ecommerceObjectIndex === null && filterTypeIndex === 'null') ||
    Number(ecommerceObjectIndex) === Number(filterTypeIndex)
  );
};

export default FiltersRow;
