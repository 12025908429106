import React, { useContext, useMemo, useState } from 'react';
import {
  Button,
  Card,
  Stack,
  Box,
  Typography,
  Menu,
  MenuItem,
  IconButton
} from '@mui/material';
import { Field, FieldArray, Form, Formik } from 'formik';
import { TextField } from 'formik-mui';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { gql } from '@apollo/client';
import PropTypes from 'prop-types';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

import ContainerPage from '../../../../../layouts/ContainerPage';
import Breadcrumbs from '../../../../../atoms/Breadcrumbs';
import UserContext from '../../../../../context';
import ChannelModal from '../../Filters/ChannelModal';
import { useParams } from 'react-router-dom';
import {
  useCustomMutation,
  useCustomQuery
} from '../../../../../hooks/graphql';
import CustomAttributeModal from '../../Filters/CustomAttributeModal';
import CustomAttributeNumericModal from '../../Filters/CustomAttributeNumericModal';
import TargetModal from '../../Filters/TargetModal';
import DeviceOsModal from '../../Filters/DeviceOsModal';
import DeviceTypeModal from '../../Filters/DeviceTypeModal';
import DeviceBrowserModal from '../../Filters/DeviceBrowserModal';
import ViewportModal from '../../Filters/ViewportModal';
import ConversionTypeModal from '../../Filters/ConversionType';
import ProductBrandModal from '../../Filters/ProductBrandModal';
import ProductCatModal from '../../Filters/ProductCatModal';
import ProductIdModal from '../../Filters/ProductIdModal';
import { useAnalyticsHelpers } from '../../../../../hooks/analyticsHelpers';
import FiltersRow from '../../Filters/FiltersRow';
import { GET_CLIENT_SETTINGS } from '../../../../../graphql/queries';
import Modal from '../../../../../atoms/Modal';
import { rbac } from '../../../../../rbac';

const CLIENT_TEAM = gql`
  query ClientTeam($clientId: ID!) {
    client(clientId: $clientId) {
      id
      team {
        id
        group
        accessAllViews
        availableViews {
          id
          name
        }
      }
    }
  }
`;

const UPDATE_CLIENT_VIEWS = gql`
  mutation UpdateClientViews($clientId: ID!, $views: [ClientView_Input]!) {
    updateAttributionViews(clientId: $clientId, views: $views) {
      id
    }
  }
`;

const UpdateView = ({ viewId: propViewId, readOnly, isModalView }) => {
  const { viewId } = useParams();
  const [anchorEl, setAnchorEl] = useState(null);
  const [isFilterOpen, setFilterOpen] = useState(null);
  const { t } = useTranslation();
  const { clientSettings, reloadMyself } = useContext(UserContext);
  const [customAttributeActiveId, setCustomAttributeActiveId] = useState(null);
  const { hasAccessToOmnicanal } = useAnalyticsHelpers();
  const [activeProductObjectIndex, setActiveProductObjectIndex] = useState(
    undefined
  );

  const { data: dataTeam } = useCustomQuery(CLIENT_TEAM, {
    variables: {
      clientId: clientSettings.id
    },
    skip: !clientSettings.id
  });

  const viewToUpdateData = useMemo(() => {
    if (!viewId && !propViewId) {
      return null;
    }

    return clientSettings.attributionViews.find(
      ({ id }) =>
        id.toString() === viewId?.toString() ||
        id.toString() === propViewId?.toString()
    );
  }, [viewId, propViewId, clientSettings]);

  const [updateAttributionViews] = useCustomMutation(UPDATE_CLIENT_VIEWS, {
    successMessage: viewToUpdateData
      ? t('ma.settings.attribution.views.viewUpdated')
      : t('ma.settings.attribution.views.viewCreated'),
    refetchQueries: [GET_CLIENT_SETTINGS],
    onCompleted: () => {
      reloadMyself();
    }
  });

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Formik
      validationSchema={Yup.object().shape({
        name: Yup.string().required(t('commun.form.requiredField')),
        filters: Yup.array(
          Yup.object().shape({
            type: Yup.string().required(t('commun.form.requiredField')),
            ids: Yup.array(Yup.string()).required(
              t('commun.form.requiredField')
            )
          })
        )
      })}
      initialValues={{
        name: viewToUpdateData?.name || '',
        filters: viewToUpdateData?.filters || []
      }}
      enableReinitialize
      onSubmit={async (values, { setSubmitting }) => {
        const variables = {
          views: [
            ...clientSettings.attributionViews.filter(
              ({ id }) =>
                (!viewId || id.toString() !== viewId.toString()) &&
                (!propViewId || id.toString() !== propViewId.toString())
            ),
            {
              ...viewToUpdateData,
              name: values.name,
              filters: values.filters
            }
          ].map(view => ({
            ...view,
            usersAccess: dataTeam.client.team
              .filter(
                teamUser =>
                  !teamUser.accessAllViews &&
                  teamUser.availableViews.some(({ id }) => id === view.id)
              )
              .map(({ id }) => id)
          }))
        };

        await updateAttributionViews({
          variables
        });

        setSubmitting(false);
      }}
    >
      {({
        values,
        errors,
        setFieldValue,
        resetForm,
        isSubmitting,
        handleSubmit,
        touched
      }) => (
        <Form>
          <Stack spacing={2} mt={2}>
            <Card sx={{ ...(isModalView ? { boxShadow: 'none' } : {}) }}>
              <Box m={isModalView ? 0 : 2}>
                <Stack spacing={2} mt={2}>
                  <Field
                    disabled={readOnly}
                    component={TextField}
                    type="string"
                    fullWidth
                    name="name"
                    label={t('ma.costs.settings.name')}
                  />

                  <Typography variant="h5">
                    {t('ma.settings.attribution.views.appliedFilters')}
                  </Typography>

                  <Stack spacing={1}>
                    <FieldArray name="filters">
                      {filtersHelpers => (
                        <>
                          <Stack direction="row" flexWrap={'wrap'}>
                            {!readOnly && (
                              <Box>
                                <Button
                                  variant="contained"
                                  onClick={handleClick}
                                  size="small"
                                  color="secondary"
                                >
                                  {t('ma.settings.attribution.views.addFilter')}
                                </Button>
                              </Box>
                            )}
                            <FiltersRow
                              filters={values.filters}
                              handleOnDelete={
                                readOnly
                                  ? undefined
                                  : (type, ids, indexFilter) => {
                                      filtersHelpers.remove(indexFilter);
                                      if (ids.length) {
                                        filtersHelpers.push({
                                          type,
                                          ids
                                        });
                                      }
                                    }
                              }
                              handleOnClick={
                                readOnly
                                  ? undefined
                                  : filterId => {
                                      if (
                                        [
                                          'channel',
                                          'subChannel',
                                          'cutting',
                                          'cuttingContainsOr',
                                          'cuttingContainsAnd'
                                        ].includes(filterId)
                                      ) {
                                        setFilterOpen('channel');
                                      }

                                      if (
                                        [
                                          'target',
                                          'targetContainsOr',
                                          'targetNotContainsOr',
                                          'targetContainsAnd',
                                          'targetNotContainsAnd'
                                        ].includes(filterId)
                                      ) {
                                        setFilterOpen('target');
                                      }

                                      if (filterId === 'conversion_type') {
                                        setFilterOpen('conversionType');
                                      }

                                      if (filterId === 'device_type') {
                                        setFilterOpen('deviceType');
                                      }

                                      if (filterId === 'device_os') {
                                        setFilterOpen('deviceOs');
                                      }

                                      if (filterId === 'device_browser') {
                                        setFilterOpen('deviceBrowser');
                                      }

                                      if (filterId === 'viewport') {
                                        setFilterOpen('viewport');
                                      }

                                      if (
                                        filterId.startsWith('product_brand')
                                      ) {
                                        setFilterOpen('productBrand');
                                        setActiveProductObjectIndex(
                                          filterId.split('-')[1]
                                        );
                                      }

                                      if (
                                        filterId.startsWith('product_id') ||
                                        filterId.startsWith('product_name')
                                      ) {
                                        setFilterOpen('productId');
                                        setActiveProductObjectIndex(
                                          filterId.split('-')[1]
                                        );
                                      }

                                      if (filterId.startsWith('product_cat')) {
                                        setFilterOpen('productCat');
                                        setActiveProductObjectIndex(
                                          filterId.split('-')[1]
                                        );
                                      }

                                      if (
                                        filterId.startsWith(
                                          'customAttribute_number_'
                                        )
                                      ) {
                                        setFilterOpen('customAttributeNumber');
                                        setCustomAttributeActiveId(
                                          filterId.replace(
                                            'customAttribute_number_',
                                            ''
                                          )
                                        );
                                      }

                                      if (
                                        filterId.startsWith(
                                          'audienceDimension_number_'
                                        )
                                      ) {
                                        setFilterOpen('customAttributeNumber');
                                        setCustomAttributeActiveId(
                                          filterId.replace(
                                            'audienceDimension_number_',
                                            ''
                                          )
                                        );
                                      }

                                      if (
                                        filterId.startsWith(
                                          'customAttribute_boolean_'
                                        )
                                      ) {
                                        setFilterOpen('customAttribute');
                                        setCustomAttributeActiveId(
                                          filterId.replace(
                                            'customAttribute_boolean_',
                                            ''
                                          )
                                        );
                                      }

                                      if (
                                        filterId.startsWith(
                                          'audienceDimension_boolean_'
                                        )
                                      ) {
                                        setFilterOpen('customAttribute');
                                        setCustomAttributeActiveId(
                                          filterId.replace(
                                            'audienceDimension_boolean_',
                                            ''
                                          )
                                        );
                                      }

                                      if (
                                        filterId.startsWith(
                                          'customAttribute_string_'
                                        )
                                      ) {
                                        setFilterOpen('customAttribute');
                                        setCustomAttributeActiveId(
                                          filterId.replace(
                                            'customAttribute_string_',
                                            ''
                                          )
                                        );
                                      }

                                      if (
                                        filterId.startsWith(
                                          'audienceDimension_string_'
                                        )
                                      ) {
                                        setFilterOpen('customAttribute');
                                        setCustomAttributeActiveId(
                                          filterId.replace(
                                            'audienceDimension_string_',
                                            ''
                                          )
                                        );
                                      }
                                    }
                              }
                            />
                          </Stack>

                          {isFilterOpen === 'customAttribute' && (
                            <CustomAttributeModal
                              id={customAttributeActiveId}
                              handleClose={() => {
                                setCustomAttributeActiveId(null);
                                setFilterOpen(null);
                              }}
                              isOpen={
                                customAttributeActiveId !== null &&
                                isFilterOpen === 'customAttribute'
                              }
                              viewFilters={[]}
                              selectedFilters={values.filters}
                              handleOnUpdate={(filterType, values) => {
                                const filter = filtersHelpers.form.values.filters.find(
                                  ({ type }) => filterType === type
                                );
                                if (filter) {
                                  filtersHelpers.remove(filter);
                                }
                                filtersHelpers.push({
                                  type: filterType,
                                  ids: values
                                });
                              }}
                            />
                          )}
                          {isFilterOpen === 'customAttributeNumber' && (
                            <CustomAttributeNumericModal
                              id={customAttributeActiveId}
                              handleClose={() => {
                                setCustomAttributeActiveId(null);
                                setFilterOpen(null);
                              }}
                              isOpen={
                                customAttributeActiveId !== null &&
                                isFilterOpen === 'customAttributeNumber'
                              }
                              viewFilters={[]}
                              selectedFilters={values.filters}
                              handleOnUpdate={(filterType, values) => {
                                const filter = filtersHelpers.form.values.filters.find(
                                  ({ type }) => filterType === type
                                );
                                if (filter) {
                                  filtersHelpers.remove(filter);
                                }
                                filtersHelpers.push({
                                  type: filterType,
                                  ids: values
                                });
                              }}
                            />
                          )}
                          <TargetModal
                            isOpen={isFilterOpen === 'target'}
                            handleClose={() => setFilterOpen(null)}
                            viewFilters={[]}
                            selectedFilters={values.filters}
                            handleOnUpdate={(
                              urlValues,
                              containsValues,
                              targetFilterType,
                              containsType
                            ) => {
                              if (
                                urlValues?.length > 0 ||
                                values.filters.find(p => p.type === 'target')
                              ) {
                                const filter = filtersHelpers.form.values.filters.find(
                                  ({ type }) => type === 'target'
                                );
                                if (filter) {
                                  filtersHelpers.remove(filter);
                                }
                                filtersHelpers.push({
                                  type: 'target',
                                  ids: urlValues
                                });
                              }

                              if (targetFilterType === 'contains') {
                                let filter = filtersHelpers.form.values.filters.find(
                                  ({ type }) => type === 'targetContainsOr'
                                );
                                if (filter) {
                                  filtersHelpers.remove(filter);
                                }
                                filter = filtersHelpers.form.values.filters.find(
                                  ({ type }) => type === 'targetContainsAnd'
                                );
                                if (filter) {
                                  filtersHelpers.remove(filter);
                                }
                                if (containsType === 'or') {
                                  filtersHelpers.push(
                                    {
                                      type: 'targetContainsOr',
                                      ids: containsValues.filter(p => p)
                                    },
                                    { type: 'targetContainsAnd', ids: [] }
                                  );
                                }
                                if (containsType === 'and') {
                                  filtersHelpers.push(
                                    {
                                      type: 'targetContainsAnd',
                                      ids: containsValues.filter(p => p)
                                    },
                                    { type: 'targetContainsOr', ids: [] }
                                  );
                                }
                              }

                              if (targetFilterType === 'notContains') {
                                let filter = filtersHelpers.form.values.filters.find(
                                  ({ type }) => type === 'targetNotContainsOr'
                                );
                                if (filter) {
                                  filtersHelpers.remove(filter);
                                }
                                filter = filtersHelpers.form.values.filters.find(
                                  ({ type }) => type === 'targetNotContainsAnd'
                                );
                                if (filter) {
                                  filtersHelpers.remove(filter);
                                }
                                if (containsType === 'or') {
                                  filtersHelpers.push(
                                    {
                                      type: 'targetNotContainsOr',
                                      ids: containsValues.filter(p => p)
                                    },
                                    { type: 'targetNotContainsAnd', ids: [] }
                                  );
                                }
                                if (containsType === 'and') {
                                  filtersHelpers.push(
                                    {
                                      type: 'targetNotContainsAnd',
                                      ids: containsValues.filter(p => p)
                                    },
                                    { type: 'targetNotContainsOr', ids: [] }
                                  );
                                }
                              }
                            }}
                          />
                          <ChannelModal
                            isOpen={isFilterOpen === 'channel'}
                            handleClose={() => setFilterOpen(null)}
                            handleOnUpdate={(
                              channelValues,
                              subChannelValues,
                              cuttingExactValues,
                              cuttingContainsValues,
                              cuttingFilterType,
                              cuttingContainsType
                            ) => {
                              if (channelValues.length > 0) {
                                const filter = filtersHelpers.form.values.filters.find(
                                  ({ type }) => type === 'channel'
                                );
                                if (filter) {
                                  filtersHelpers.remove(filter);
                                }
                                filtersHelpers.push({
                                  type: 'channel',
                                  ids: channelValues.map(({ id }) => id)
                                });
                              }
                              if (subChannelValues.length > 0) {
                                const filter = filtersHelpers.form.values.filters.find(
                                  ({ type }) => type === 'subChannel'
                                );
                                if (filter) {
                                  filtersHelpers.remove(filter);
                                }
                                filtersHelpers.push({
                                  type: 'subChannel',
                                  ids: subChannelValues.map(({ id }) => id)
                                });
                              }
                              if (
                                cuttingExactValues.length > 0 &&
                                cuttingFilterType === 'exact'
                              ) {
                                const filter = filtersHelpers.form.values.filters.find(
                                  ({ type }) => type === 'cutting'
                                );
                                if (filter) {
                                  filtersHelpers.remove(filter);
                                }
                                filtersHelpers.push(
                                  {
                                    type: 'cutting',
                                    ids: cuttingExactValues.map(id => id)
                                  },
                                  { type: 'cuttingContainsAnd', ids: [] },
                                  { type: 'cuttingContainsOr', ids: [] }
                                );
                              }

                              if (cuttingFilterType === 'contains') {
                                if (cuttingContainsType === 'or') {
                                  const filter = filtersHelpers.form.values.filters.find(
                                    ({ type }) => type === 'cuttingContainsOr'
                                  );
                                  if (filter) {
                                    filtersHelpers.remove(filter);
                                  }
                                  filtersHelpers.push(
                                    {
                                      type: 'cuttingContainsOr',
                                      ids: cuttingContainsValues.map(id => id)
                                    },
                                    { type: 'cuttingContainsAnd', ids: [] },
                                    { type: 'cutting', ids: [] }
                                  );
                                }
                                if (cuttingContainsType === 'and') {
                                  const filter = filtersHelpers.form.values.filters.find(
                                    ({ type }) => type === 'cuttingContainsAnd'
                                  );
                                  if (filter) {
                                    filtersHelpers.remove(filter);
                                  }
                                  filtersHelpers.push(
                                    {
                                      type: 'cuttingContainsAnd',
                                      ids: cuttingContainsValues.map(id => id)
                                    },
                                    { type: 'cuttingContainsOr', ids: [] },
                                    { type: 'cutting', ids: [] }
                                  );
                                }
                              }
                            }}
                            selectedFilters={values.filters}
                            viewFilters={[]}
                          />
                          <DeviceTypeModal
                            isOpen={isFilterOpen === 'deviceType'}
                            handleClose={() => setFilterOpen(null)}
                            viewFilters={[]}
                            selectedFilters={values.filters}
                            handleOnUpdate={values => {
                              const filter = filtersHelpers.form.values.filters.find(
                                ({ type }) => type === 'device_type'
                              );
                              if (filter) {
                                filtersHelpers.remove(filter);
                              }
                              filtersHelpers.push({
                                type: 'device_type',
                                ids: values
                              });
                            }}
                          />
                          <DeviceOsModal
                            isOpen={isFilterOpen === 'deviceOs'}
                            handleClose={() => setFilterOpen(null)}
                            viewFilters={[]}
                            selectedFilters={values.filters}
                            handleOnUpdate={values => {
                              const filter = filtersHelpers.form.values.filters.find(
                                ({ type }) => type === 'device_os'
                              );
                              if (filter) {
                                filtersHelpers.remove(filter);
                              }
                              filtersHelpers.push({
                                type: 'device_os',
                                ids: values
                              });
                            }}
                          />
                          <DeviceBrowserModal
                            isOpen={isFilterOpen === 'deviceBrowser'}
                            handleClose={() => setFilterOpen(null)}
                            viewFilters={[]}
                            selectedFilters={values.filters}
                            handleOnUpdate={values => {
                              const filter = filtersHelpers.form.values.filters.find(
                                ({ type }) => type === 'device_browser'
                              );
                              if (filter) {
                                filtersHelpers.remove(filter);
                              }
                              filtersHelpers.push({
                                type: 'device_browser',
                                ids: values
                              });
                            }}
                          />
                          <ViewportModal
                            isOpen={isFilterOpen === 'viewport'}
                            handleClose={() => setFilterOpen(null)}
                            viewFilters={[]}
                            selectedFilters={values.filters}
                            handleOnUpdate={values => {
                              const filter = filtersHelpers.form.values.filters.find(
                                ({ type }) => type === 'viewport'
                              );
                              if (filter) {
                                filtersHelpers.remove(filter);
                              }
                              filtersHelpers.push({
                                type: 'viewport',
                                ids: values
                              });
                            }}
                          />
                          <ConversionTypeModal
                            isOpen={isFilterOpen === 'conversionType'}
                            handleClose={() => setFilterOpen(null)}
                            viewFilters={[]}
                            selectedFilters={values.filters}
                            handleOnUpdate={values => {
                              const filter = filtersHelpers.form.values.filters.find(
                                ({ type }) => type === 'conversion_type'
                              );
                              if (filter) {
                                filtersHelpers.remove(filter);
                              }
                              filtersHelpers.push({
                                type: 'conversion_type',
                                ids: values
                              });
                            }}
                          />

                          <ProductBrandModal
                            isOpen={
                              isFilterOpen === 'productBrand' &&
                              activeProductObjectIndex !== undefined
                            }
                            handleClose={() => {
                              setFilterOpen(null);
                              setActiveProductObjectIndex(undefined);
                            }}
                            activeProductObjectIndex={activeProductObjectIndex}
                            viewFilters={[]}
                            selectedFilters={values.filters}
                            handleOnUpdate={values => {
                              const filter = filtersHelpers.form.values.filters.find(
                                ({ type }) =>
                                  type ===
                                  `product_brand-${activeProductObjectIndex}`
                              );
                              if (filter) {
                                filtersHelpers.remove(filter);
                              }
                              filtersHelpers.push({
                                type: `product_brand-${activeProductObjectIndex}`,
                                ids: values
                              });
                            }}
                          />
                          <ProductCatModal
                            isOpen={
                              isFilterOpen === 'productCat' &&
                              activeProductObjectIndex !== undefined
                            }
                            handleClose={() => {
                              setFilterOpen(null);
                              setActiveProductObjectIndex(undefined);
                            }}
                            activeProductObjectIndex={activeProductObjectIndex}
                            viewFilters={[]}
                            selectedFilters={values.filters}
                            handleOnUpdate={values => {
                              const filter = filtersHelpers.form.values.filters.find(
                                ({ type }) =>
                                  type ===
                                  `product_cat-${activeProductObjectIndex}`
                              );
                              if (filter) {
                                filtersHelpers.remove(filter);
                              }
                              filtersHelpers.push({
                                type: `product_cat-${activeProductObjectIndex}`,
                                ids: values
                              });
                            }}
                          />
                          <ProductIdModal
                            isOpen={
                              isFilterOpen === 'productId' &&
                              activeProductObjectIndex !== undefined
                            }
                            handleClose={() => {
                              setFilterOpen(null);
                              setActiveProductObjectIndex(undefined);
                            }}
                            activeProductObjectIndex={activeProductObjectIndex}
                            viewFilters={[]}
                            selectedFilters={values.filters}
                            handleOnUpdate={(
                              productValues,
                              containsValues,
                              targetFilterType,
                              containsType
                            ) => {
                              if (
                                productValues?.length > 0 ||
                                values.filters.find(
                                  p =>
                                    p ===
                                    `product_id-${activeProductObjectIndex}`
                                )
                              ) {
                                const filter = filtersHelpers.form.values.filters.find(
                                  ({ type }) =>
                                    type ===
                                    `product_id-${activeProductObjectIndex}`
                                );
                                if (filter) {
                                  filtersHelpers.remove(filter);
                                }
                                filtersHelpers.push({
                                  type: `product_id-${activeProductObjectIndex}`,
                                  ids: productValues
                                });
                              }

                              if (targetFilterType === 'contains') {
                                let filter = filtersHelpers.form.values.filters.find(
                                  ({ type }) =>
                                    type ===
                                    `product_name_ContainsOr-${activeProductObjectIndex}`
                                );
                                if (filter) {
                                  filtersHelpers.remove(filter);
                                }
                                filter = filtersHelpers.form.values.filters.find(
                                  ({ type }) =>
                                    type ===
                                    `product_name_ContainsAnd-${activeProductObjectIndex}`
                                );
                                if (filter) {
                                  filtersHelpers.remove(filter);
                                }
                                if (containsType === 'or') {
                                  filtersHelpers.push(
                                    {
                                      type: `product_name_ContainsOr-${activeProductObjectIndex}`,
                                      ids: containsValues.filter(p => p)
                                    },
                                    {
                                      type: `product_name_ContainsAnd-${activeProductObjectIndex}`,
                                      ids: []
                                    }
                                  );
                                }
                                if (containsType === 'and') {
                                  filtersHelpers.push(
                                    {
                                      type: `product_name_ContainsAnd-${activeProductObjectIndex}`,
                                      ids: containsValues.filter(p => p)
                                    },
                                    {
                                      type: `product_name_ContainsOr-${activeProductObjectIndex}`,
                                      ids: []
                                    }
                                  );
                                }
                              }

                              if (targetFilterType === 'notContains') {
                                let filter = filtersHelpers.form.values.filters.find(
                                  ({ type }) =>
                                    type ===
                                    `product_name_NotContainsOr-${activeProductObjectIndex}`
                                );
                                if (filter) {
                                  filtersHelpers.remove(filter);
                                }
                                filter = filtersHelpers.form.values.filters.find(
                                  ({ type }) =>
                                    type ===
                                    `product_name_NotContainsAnd-${activeProductObjectIndex}`
                                );
                                if (filter) {
                                  filtersHelpers.remove(filter);
                                }
                                if (containsType === 'or') {
                                  filtersHelpers.push(
                                    {
                                      type: `product_name_NotContainsOr-${activeProductObjectIndex}`,
                                      ids: containsValues.filter(p => p)
                                    },
                                    {
                                      type: `product_name_NotContainsAnd-${activeProductObjectIndex}`,
                                      ids: []
                                    }
                                  );
                                }
                                if (containsType === 'and') {
                                  filtersHelpers.push(
                                    {
                                      type: `product_name_NotContainsAnd-${activeProductObjectIndex}`,
                                      ids: containsValues.filter(p => p)
                                    },
                                    {
                                      type: `product_name_NotContainsOr-${activeProductObjectIndex}`,
                                      ids: []
                                    }
                                  );
                                }
                              }
                            }}
                          />
                        </>
                      )}
                    </FieldArray>
                  </Stack>

                  <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                    MenuListProps={{
                      'aria-labelledby': 'basic-button'
                    }}
                  >
                    <MenuItem
                      onClick={() => {
                        handleClose();
                        setFilterOpen('channel');
                      }}
                    >
                      {t('commun.channel')}
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        handleClose();
                        setFilterOpen('target');
                      }}
                    >
                      {t('commun.landingPage')}
                    </MenuItem>

                    <MenuItem
                      onClick={() => {
                        handleClose();
                        setFilterOpen('deviceType');
                      }}
                    >
                      {t('ma.commun.device')}
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        handleClose();
                        setFilterOpen('deviceOs');
                      }}
                    >
                      {t('ma.commun.operatingSystem')}
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        handleClose();
                        setFilterOpen('deviceBrowser');
                      }}
                    >
                      {t('ma.commun.browser')}
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        handleClose();
                        setFilterOpen('viewport');
                      }}
                    >
                      {t('ma.commun.windowSize')}
                    </MenuItem>

                    {hasAccessToOmnicanal && (
                      <MenuItem
                        onClick={() => {
                          handleClose();
                          setFilterOpen('conversionType');
                        }}
                      >
                        {t('ma.omnicanal.conversionTypes')}
                      </MenuItem>
                    )}

                    {clientSettings.attributionPingsSettings.customAttributes
                      ?.filter(ca => ['boolean', 'string'].includes(ca.type))
                      ?.map(ca => (
                        <MenuItem
                          key={ca.internalAttributKey}
                          onClick={() => {
                            handleClose();
                            setFilterOpen('customAttribute');
                            setCustomAttributeActiveId(ca.internalAttributKey);
                          }}
                        >
                          {t(
                            `ma.commun.${
                              ca.dimension === 'audience'
                                ? 'audienceDimension'
                                : 'customAttribute'
                            }`,
                            {
                              name: ca.name
                            }
                          )}
                        </MenuItem>
                      ))}
                    {clientSettings.attributionPingsSettings.customAttributes
                      ?.filter(ca => ['number'].includes(ca.type))
                      ?.map(ca => (
                        <MenuItem
                          key={ca.internalAttributKey}
                          onClick={() => {
                            handleClose();
                            setFilterOpen('customAttributeNumber');
                            setCustomAttributeActiveId(ca.internalAttributKey);
                          }}
                        >
                          {t(
                            `ma.commun.${
                              ca.dimension === 'audience'
                                ? 'audienceDimension'
                                : 'customAttribute'
                            }`,
                            {
                              name: ca.name
                            }
                          )}
                        </MenuItem>
                      ))}

                    {clientSettings.attributionPingsSettings.customAttributes
                      ?.filter(ca => ca.type === 'ecommerceObject')
                      ?.map((item, index) => (
                        <div key={`ecommerceObject_${index}`}>
                          <MenuItem
                            onClick={() => {
                              handleClose();
                              setActiveProductObjectIndex(
                                item.ecommerceObjectIndex
                              );
                              setFilterOpen('productBrand');
                            }}
                          >
                            {t('ma.filter.labelFilterProductBrand', {
                              name: item.name
                            })}
                          </MenuItem>
                          <MenuItem
                            onClick={() => {
                              handleClose();
                              setActiveProductObjectIndex(
                                item.ecommerceObjectIndex
                              );
                              setFilterOpen('productCat');
                            }}
                          >
                            {t('ma.filter.labelFilterProductCat', {
                              name: item.name
                            })}
                          </MenuItem>
                          <MenuItem
                            onClick={() => {
                              handleClose();
                              setActiveProductObjectIndex(
                                item.ecommerceObjectIndex
                              );
                              setFilterOpen('productId');
                            }}
                          >
                            {t('ma.filter.labelFilterProductName', {
                              name: item.name
                            })}
                          </MenuItem>
                        </div>
                      ))}
                  </Menu>
                </Stack>
              </Box>
            </Card>
            {!readOnly && (
              <Stack direction="row" justifyContent="flex-end" spacing={2}>
                <Button
                  disabled={isSubmitting}
                  color="secondary"
                  onClick={() => resetForm()}
                >
                  {t('commun.cancel')}
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  disabled={isSubmitting}
                  onClick={handleSubmit}
                >
                  {t('commun.validate')}
                </Button>
              </Stack>
            )}
          </Stack>
        </Form>
      )}
    </Formik>
  );
};

UpdateView.defaultProps = {
  readOnly: false,
  isModalView: false,
  viewId: undefined
};

UpdateView.propTypes = {
  readOnly: PropTypes.bool,
  isModalView: PropTypes.bool,
  viewId: PropTypes.string
};

const UpdateViewPage = () => {
  const { viewId } = useParams();
  const { t } = useTranslation();
  const { selectedView, clientSettings, userGroup } = useContext(UserContext);

  const isReadOnly = useMemo(
    () => rbac['accessReadOnlyMarketingAnalyticsSettings'].includes(userGroup),
    [userGroup]
  );

  const viewToUpdateData = useMemo(() => {
    if (!viewId) {
      return null;
    }

    return clientSettings.attributionViews.find(
      ({ id }) => id.toString() === viewId.toString()
    );
  }, [viewId, clientSettings]);

  return (
    <ContainerPage
      maxWidth="md"
      title={
        viewToUpdateData
          ? t('ma.settings.attribution.views.updateView', {
              view: viewToUpdateData.name
            })
          : t('ma.settings.attribution.views.addView')
      }
    >
      <Breadcrumbs
        title={
          viewToUpdateData
            ? t('ma.settings.attribution.views.updateView', {
                view: viewToUpdateData.name
              })
            : t('ma.settings.attribution.views.addView')
        }
        links={[
          {
            link: `/marketing-analytics/view/${selectedView}/settings`,
            title: t('commun.parameters')
          },
          {
            link: `/marketing-analytics/view/${selectedView}/settings/views`,
            title: t('ma.settings.attribution.views.views')
          }
        ]}
      />

      <UpdateView readOnly={isReadOnly} />
    </ContainerPage>
  );
};

const UpdateViewModal = ({ viewId }) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <IconButton onClick={() => setIsOpen(true)}>
        <HelpOutlineIcon sx={{ width: '0.7em', height: '0.7em' }} />
      </IconButton>
      <Modal
        title={t('ma.settings.attribution.views.viewDetails')}
        showCloseIcon
        open={isOpen}
        maxWidth={'md'}
        fullWidth
        onClose={() => {
          setIsOpen(false);
        }}
      >
        <UpdateView readOnly={true} viewId={viewId} isModalView={true} />
      </Modal>
    </>
  );
};

UpdateViewModal.propTypes = {
  viewId: PropTypes.string.isRequired
};

export { UpdateViewModal };
export default UpdateViewPage;
